<template>
  <div>
    <MainLayout>
      <template v-slot:toolbar>
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <!--begin::Title-->
          <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Anunciante - {{ advertiser ? advertiser.name : '' }}</h1>
          <!--end::Title-->
        </div>
        <!--begin::Actions-->
        <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
          <div class="m-0">
            <router-link
              :to="{ name: 'advertisers.index' }"
              class="btn btn-sm btn-primary"
            >
              Voltar
            </router-link>
          </div>
          <!--end::Filter menu-->
        </div>
        <!--end::Actions-->
        <!--end::Page title-->
      </template>

      <!-- CONTENT -->
      <!--begin::Navbar-->
      <div class="card mb-5 mb-xl-10">
        <div class="card-body pt-0 pb-0">
          <!--begin::Navs-->
          <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
            <!--begin::Nav item-->
            <li class="nav-item mt-2">
              <a
                class="nav-link text-active-primary ms-0 me-10 py-5"
                :class="{
                  active: currentTab === 'details'
                }"
                href="#"
                @click.prevent="changeTab('details')"
              >
                Detalhes
              </a>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item mt-2">
              <a
                class="nav-link text-active-primary ms-0 me-10 py-5"
                :class="{
                  active: currentTab === 'audiences'
                }"
                href="#"
                @click.prevent="changeTab('audiences')"
              >
                Audiências
              </a>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item mt-2">
              <a
                class="nav-link text-active-primary ms-0 me-10 py-5"
                :class="{
                  active: currentTab === 'campaigns'
                }"
                href="#"
                @click.prevent="changeTab('campaigns')"
              >
                Campanhas
              </a>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item mt-2">
              <a
                class="nav-link text-active-primary ms-0 me-10 py-5"
                :class="{
                  active: currentTab === 'creatives'
                }"
                href="#"
                @click.prevent="changeTab('creatives')"
              >
                Criativos
              </a>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item mt-2">
              <a
                class="nav-link text-active-primary ms-0 me-10 py-5"
                :class="{
                  active: currentTab === 'coupons'
                }"
                href="#"
                @click.prevent="changeTab('coupons')"
              >
                Cupons
              </a>
            </li>
            <!--begin::Nav item-->
            <li class="nav-item mt-2">
              <a
                class="nav-link text-active-primary ms-0 me-10 py-5"
                :class="{
                  active: currentTab === 'site_lists'
                }"
                href="#"
                @click.prevent="changeTab('site_lists')"
              >
                Lista de sites
              </a>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item mt-2">
              <a
                class="nav-link text-active-primary ms-0 me-10 py-5"
                :class="{
                  active: currentTab === 'payments'
                }"
                href="#"
                @click.prevent="changeTab('payments')"
              >
                Pagamentos
              </a>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item mt-2">
              <a
                class="nav-link text-active-primary ms-0 me-10 py-5"
                :class="{
                  active: currentTab === 'pixels'
                }"
                href="#"
                @click.prevent="changeTab('pixels')"
              >
                Pixels
              </a>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item mt-2">
              <a
                class="nav-link text-active-primary ms-0 me-10 py-5"
                :class="{
                  active: currentTab === 'wallets'
                }"
                href="#"
                @click.prevent="changeTab('wallets')"
              >
                Histórico de transações
              </a>
            </li>
            <!--end::Nav item-->
          </ul>
          <!--begin::Navs-->
        </div>
      </div>
      <!--end::Navbar-->

      <!-- DETALHES TAB -->
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10" v-show="currentTab === 'details'">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Detalhes</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9">
          <template v-if="advertiserLoader">
            <div class="py-5">
              <Loader size="big" />
            </div>
          </template>
          <template v-else>
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">ID</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ advertiser.id }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Nome</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ advertiser.name }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Empresa</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">
                  <router-link
                    :to="{ name: 'companies.show', params: { id: advertiser.company.id }}"
                  >
                    {{ advertiser.company.name }}
                  </router-link>
                </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">CNPJ</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">
                  <template v-if="advertiser.document.length === 11">
                      {{ advertiser.document | VMask('###.###.###-##') }}
                    </template>
                    <template v-else>
                      {{ advertiser.document | VMask('##.###.###/####-##') }}
                    </template>
                  </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Moeda</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">
                  {{ advertiser.currency_type | formatCurrencyType }}
                </span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Website</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6"><a :href="advertiser.website" target="_blank">{{ advertiser.website }}</a></span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Status</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ advertiser.status | formatStatus }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Valor disponível</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ advertiser.available_amount/100 | formatMoney(advertiser.currency_type) }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Valor alocado</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ advertiser.allocated_amount/100 | formatMoney(advertiser.currency_type) }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Valor pendente</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ advertiser.pending_amount/100 | formatMoney(advertiser.currency_type) }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">ID Google</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ advertiser.google_id }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Gasto</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ advertiser.spending/100 | formatMoney(advertiser.currency_type) }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Impressões</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ advertiser.impressions }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Cliques</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ advertiser.clicks }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Conversões</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ advertiser.conversions }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">CTR</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ advertiser.ctr }}%</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Data de criação</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ advertiser.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Data de última atualização</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ advertiser.updated_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!-- IMPERSONATE -->
            <div class="row mb-7">
              <h2 class="m-0 text-gray-900 flex-grow-1">Impersonate</h2>
              <div class="mt-5">
                <ImpersonateButton
                  :userId="advertiser.user_impersonate_id"
                />
              </div>
            </div> <!-- END IMPERSONATE -->
          </template>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View--> <!-- END DETAILS TAB -->

      <!-- AUDIENCES TAB -->
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10" v-show="currentTab === 'audiences'">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Audiências Customizadas</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9" v-if="advertiser">
          <CustomTargetingListTable
            :advertiserId="advertiser.id"
            :types="['EMAIL_AUDIENCE']"
          />
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View--> <!-- END AUDIENCES TAB -->

      <!-- CAMPAIGNS TAB -->
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10" v-show="currentTab === 'campaigns'">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Campanhas</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9" v-if="advertiser">
          <CampaignListTable
            :advertiserId="advertiser.id"
          />
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View--> <!-- END CAMPAIGNS TAB -->

      <!-- CREATIVES TAB -->
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10" v-show="currentTab === 'creatives'">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Criativos</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9" v-if="advertiser">
          <CreativeListTable
            :advertiserId="advertiser.id"
          />
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View--> <!-- END CREATIVES TAB -->

      <!-- COUPONS TAB -->
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10" v-show="currentTab === 'coupons'">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Cupons utilizados</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9" v-if="advertiser">
          <template v-if="couponsLoader">
            <div class="py-5">
              <Loader size="big" />
            </div>
          </template>
          <template v-else>
            <p class="text-center" v-if="coupons.length === 0">
              Não foram utilizados cupons
            </p>
            <div v-else>
              <!--begin::Table-->
              <table class="table align-middle table-row-dashed fs-6 gy-3" id="kt_table_widget_5_table">
                <!--begin::Table head-->
                <thead>
                  <!--begin::Table row-->
                  <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                    <th class="pe-3 min-w-150px">Código</th>
                    <th class="pe-3 min-w-100px">Tipo</th>
                    <th class="pe-3 min-w-100px">Data da vinculação</th>
                    <th class="pe-3 min-w-150px">Valor</th>
                    <th class="pe-3 min-w-100px">Valor remanescente</th>
                    <th class="pe-3 min-w-100px">Início de vigência</th>
                    <th class="pe-3 min-w-100px">Término de vigência</th>
                  </tr>
                  <!--end::Table row-->
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody class="fw-bold text-gray-600">
                  <tr v-for="(coupon, index) in coupons" :key="index">
                    <td class="">{{ coupon.code }}</td>
                    <td class="">{{ coupon.type }}</td>
                    <td class="">{{ coupon.usage.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</td>
                    <td class="">{{ coupon.usage.amount/100 | formatMoney(advertiser.currency_type) }}</td>
                    <td class="">{{ coupon.usage.remaining_amount / 100 | formatMoney(advertiser.currency_type) }}</td>
                    <td class="">{{ coupon.usage.valid_start_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</td>
                    <td class="">{{ coupon.usage.valid_end_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</td>
                  </tr>
                </tbody>
                <!--end::Table body-->
              </table>
              <!--end::Table-->
            </div>
          </template>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View--> <!-- END COUPONS TAB -->

      <!-- SITE LISTS TAB -->
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10" v-show="currentTab === 'site_lists'">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Lista de sites</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9" v-if="advertiser">
          <CustomTargetingListTable
            :advertiserId="advertiser.id"
            :types="['SITE_LIST']"
          />
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View--> <!-- END SITE LISTS TAB -->

      <!-- ORDERS TAB -->
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10" v-show="currentTab === 'payments'">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Pagamentos</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9" v-if="advertiser">
          <OrderListTable
            :advertiserId="advertiser.id"
          />
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View--> <!-- END ORDERS TAB -->

      <!-- PIXELS TAB -->
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10" v-show="currentTab === 'pixels'">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Pixels</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9">
          <template v-if="pixelsLoader && pixels.length === 0">
            <div class="py-5">
              <Loader size="big" />
            </div>
          </template>
          <template v-else>
            <div class="table-responsive">
              <!--begin::Table-->
              <table class="table align-middle table-row-dashed fs-6 gy-5">
                <!--begin::Table head-->
                <thead>
                  <!--begin::Table row-->
                  <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                    <th class="min-w-100px">Nome do pixel</th>
                    <th class="min-w-70px">Nome da regra</th>
                    <th class="min-w-100px">Objetivo</th>
                    <th class="min-w-70px">Nome do evento</th>
                    <th class="min-w-70px">Status</th>
                  </tr>
                  <!--end::Table row-->
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody class="fw-semibold text-gray-600">
                  <!--begin::Table row-->
                  <tr v-for="(pixel, index) in pixels" :key="index">
                    <td>
                      <span>{{ pixel.pixel.name }}</span>
                    </td>
                    <td>
                      <span>{{ pixel.name }}</span>
                    </td>
                    <td>
                      <span>{{ pixel.objective }}</span>
                    </td>
                    <td>
                      <span>{{ pixel.event_name }}</span>
                    </td>
                    <td>
                      <span>{{ pixel.status | formatStatus }}</span>
                    </td>
                  </tr>
                  <!--end::Table row-->
                </tbody>
                <!--end::Table body-->
              </table>
            </div>
            <!--end::Table-->
            <!--end::Table-->
            <div class="text-center mt-4">
              <Button
                class="btn-primary"
                :loading="pixelsLoader"
                @click="loadMorePixels"
                v-if="isLoadMorePixelsVisible"
              >
                Carregar mais
              </Button>
            </div>
          </template>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View--> <!-- END PIXELS TAB -->

      <!-- WALLETS TAB -->
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10" v-show="currentTab === 'wallets'">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Histórico de transações</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9" v-if="advertiser">
          <div class="mb-3">
            <p>
              <strong>Valor disponível:</strong> {{ advertiser.available_amount/100 | formatMoney(advertiser.currency_type) }}<br/>
              <strong>Valor alocado:</strong> {{ advertiser.allocated_amount/100 | formatMoney(advertiser.currency_type) }}
            </p>
          </div>
          <WalletListTable
            :advertiserId="advertiser.id"
            :currency="advertiser.currency_type"
          />
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View--> <!-- END WALLETS TAB -->

      <!-- END CONTENT -->
    </MainLayout>
  </div>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import { mask } from 'vue-the-mask'
import PixelRulesService from '@/modules/pixels/services/pixel-rules-service'

import Button from '@/components/common/Button/Button'
import CampaignListTable from '@/components/campaigns/CampaignListTable'
import CreativeListTable from '@/components/creatives/CreativeListTable'
import CustomTargetingListTable from '@/components/custom-targetings/CustomTargetingListTable'
import ImpersonateButton from '@/components/users/ImpersonateButton'
import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'
import OrderListTable from '@/components/orders/OrderListTable'
import WalletListTable from '@/components/wallets/WalletListTable'

export default {
  directives: { mask },
  components: {
    Button,
    CampaignListTable,
    CreativeListTable,
    CustomTargetingListTable,
    ImpersonateButton,
    Loader,
    MainLayout,
    OrderListTable,
    WalletListTable
  },
  metaInfo () {
    return {
      title: this.advertiser ? this.advertiser.name : ''
    }
  },
  data () {
    return {
      advertiser: '',
      advertiserLoader: false,
      currentTab: 'details',
      coupons: [],
      couponsLoader: false,
      isLoadMorePixelsVisible: false,
      pixels: [],
      pixelsLoader: false,
      pixelsPage: 1
    }
  },
  created () {
    this.getAdvertiser()
  },
  computed: {
    /**
     * Current tab title
     */
    tabTitle () {
      switch (this.currentTab) {
        case 'packages':
          return 'Grupos de Estratégias'
        default:
          return 'Detalhes'
      }
    }
  },
  methods: {
    /**
     * Change current tab
     */
    changeTab (tab) {
      this.currentTab = tab
    },
    /**
     * Get advertiser
     */
    async getAdvertiser () {
      this.advertiserLoader = true
      try {
        const advertiser = await AdvertisersService.getAdvertiser(this.$route.params.id)
        this.advertiser = advertiser

        this.getCoupons()
        this.getPixelRules()
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.advertiserLoader = false
    },
    /**
     * Get coupons
     */
    async getCoupons () {
      this.couponsLoader = true
      try {
        const coupons = await AdvertisersService.getUsedCouponsByAdvertiser(this.$route.params.id)
        this.coupons = coupons
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.couponsLoader = false
    },
    /**
     * Get pixel rules
     */
    async getPixelRules () {
      this.pixelsLoader = true
      try {
        const rules = await PixelRulesService.getPixelRules({
          advertiser_id: this.$route.params.id,
          page: this.pixelsPage
        })
        this.pixels = [...this.pixels, ...rules.data]
        this.isLoadMorePixelsVisible = rules.meta.current_page !== rules.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.pixelsLoader = false
    },
    /**
     * Load more pixels
     */
    loadMorePixels () {
      this.pixelsPage++
      this.getPixelRules()
    }
  }
}
</script>
