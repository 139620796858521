<template>
  <div class="campaign-list-table">
    <div v-if="loader && localWallets.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else-if="!loader && localWallets.length === 0">
      <p class="text-center pt-5" v-if="advertiserId">Nenhuma transação encontrada no anunciante</p>
      <p class="text-center pt-5" v-else-if="campaignId">Nenhuma transação encontrada na campanha</p>
      <p class="text-center pt-5" v-else-if="companyId">Nenhuma transação encontrada na empresa</p>
      <p class="text-center pt-5" v-else>Nenhuma transação encontrada</p>
    </template>
    <template v-else>
      <div class="table-responsive">
        <!--begin::Table-->
        <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
          <!--begin::Table head-->
          <thead>
            <!--begin::Table row-->
            <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
              <th class="min-w-100px">Tipo</th>
              <th class="min-w-70px">Valor</th>
              <th class="min-w-70px">Data</th>
              <th class="min-w-70px">Descrição</th>
            </tr>
            <!--end::Table row-->
          </thead>
          <!--end::Table head-->
          <!--begin::Table body-->
          <tbody class="fw-semibold text-gray-600">
            <!--begin::Table row-->
            <tr v-for="(wallet, index) in localWallets" :key="index">
              <td>
                <span>{{ wallet.type }}</span>
              </td>
              <td>
                <span>{{ wallet.amount/100 | formatMoney(currency) }}</span>
              </td>
              <td>
                <span>{{ wallet.payment_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
              </td>
              <td>
                <span>{{ wallet.description }}</span>
              </td>
            </tr>
            <!--end::Table row-->
          </tbody>
          <!--end::Table body-->
        </table>
      </div>
      <!--end::Table-->
      <div class="text-center mt-4">
        <Button
          class="btn-primary"
          :loading="loader"
          @click="loadMore"
          v-if="isLoadMoreVisible"
        >
          Carregar mais
        </Button>
      </div>
    </template>
  </div>
</template>

<script>

import WalletsService from '@/modules/wallets/services/wallets-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'WalletListTable',
  props: ['advertiserId', 'campaignId', 'companyId', 'wallets', 'currency'],
  components: {
    Button,
    Loader
  },
  data () {
    return {
      localWallets: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true
    }
  },
  created () {
    if (this.wallets) {
      this.localWallets = this.wallets
      this.isLoadMoreVisible = false
    } else {
      this.getWallets()
    }
  },
  watch: {
    reload (newValue) {
      if (newValue) this.reset()
    }
  },
  methods: {
    /**
     * Get wallets
     */
    async getWallets () {
      if (!this.advertiserId && !this.campaignId && !this.companyId) return false

      this.loader = true
      try {
        const wallets = await WalletsService.getWallets({
          advertiser_id: this.advertiserId,
          campaign_id: this.campaignId,
          company_id: this.companyId,
          page: this.page
        })
        this.localWallets = [...this.localWallets, ...wallets.data]
        this.isLoadMoreVisible = wallets.meta.current_page !== wallets.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getWallets()
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localWallets = []
      this.getWallets()
    }
  }
}
</script>
